// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad from 'lozad';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import SlideUpDown from 'vue-slide-up-down';

// Font Awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {faFacebookF, faTwitter, faLinkedinIn, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'
import {
    faCalendarDay,
} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import {
    faUser,
    faUserEdit,
    faKey,
    faClock,
    faAngleRight,
    faAngleLeft,
    faPlus,
    faGlobe,
    faEdit,
    faPencilAlt,
    faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
    faUser,
    faUserEdit,
    faKey,
    faClock,
    faAngleRight,
    faAngleLeft,
    faPlus,
    faGlobe,
    faEdit,
    faPencilAlt,
    faTrashAlt,
    faEnvelope,
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faYoutube
)

// Vee-validate
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required, required_if, confirmed, min} from 'vee-validate/dist/rules'

// Vee-validate rules
extend('required', {
    ...required,
    message: 'Dit veld is vereist.'
});

extend('required_if', {
    ...required_if,
    message: 'Dit veld is vereist.'
});

extend('email', {
    ...email,
    message: 'Dit is geen geldig e-mail adres.'
});

extend('confirmed', {
    ...confirmed,
    message: 'De wachtwoorden komen niet overeen.'
});

extend('min', {
    ...min,
    message: 'Minstens 6 karakters.'
});

extend("date", {
    validate: value => /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(value),
    message: "De datum staat niet in het juiste formaat dd/mm/yyyy"
});

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: function (el) {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) {
                img[0].style.objectPosition = cropPos;
            }
        }
    }
});

// Import our CSS
import styles from '../css/app.pcss';
import VueSmoothScroll from "vue2-smooth-scroll";


// App main
const main = async () => {
    // Async load the vue module
    const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue');
    const {default: axios} = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} = await import( /* webpackChunkName: "body-scroll-lock" */ 'body-scroll-lock');
    const {default: VueAnimateNumber} = await import(/* webpackChunkName: "animated-number-vue" */ 'vue-animate-number');
    const {default: VueWaypoint} = await import(/* webpackChunkName: "vue-waypoint" */ 'vue-waypoint');
    const {default: VueSmoothScroll} = await import(/* webpackChunkName: "vue2-smooth-scroll" */ 'vue2-smooth-scroll');

    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('font-awesome-icon', FontAwesomeIcon)
    Vue.use(VueSmoothScroll)
    Vue.use(VueWaypoint);
    Vue.use(VueCookies);
    Vue.use(VueAnimateNumber)

    // Vue.component('slide-up-down', SlideUpDown);

    window.axios = axios;

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        components: {
            'entry-carousel': ()            => import('../vue/EntryCarousel.vue'),
            'image-carousel': ()            => import('../vue/ImageCarousel.vue'),
            'quotes-carousel': ()           => import('../vue/QuotesCarousel.vue'),
            'grid': ()                      => import('../vue/Grid.vue'),
            'number': ()                    => import('../vue/Number.vue'),
            'story-filters': ()             => import('../vue/StoryFilters.vue'),
            'event-filters': ()             => import('../vue/EventFilters.vue'),
            'event-form': ()                => import('../vue/EventForm.vue'),
            'action-form': ()               => import('../vue/ActionForm.vue'),
            'story-form': ()                => import('../vue/StoryForm.vue'),
            'events-map': ()                => import('../vue/EventsMap.vue'),
            'events-map-cities': ()         => import('../vue/EventsMapCities.vue'),
            'event-map': ()                 => import('../vue/EventMap.vue'),
            'newsletter': ()                => import('../vue/Newsletter.vue')
        },
        data: {
            // Cookie consent
            showStatusBar: true,
            mobileMenuActive: false,
            navClassObject: {
                active: false,
                hide: false,
            },

            // form fields
            loginName: '',
            firstName: '',
            lastName: '',
            email: '',
            newPassword: '',
            confirmPassword: '',
            currentPassword: '',
            password: '',
            shippingAddress: '',
            invoicingAddress: '',
            phone: '',
            product: '',
            amount: '',
        },
        mounted() {

            let vm = this

            // Select all fields with data attributes
            let fields = document.querySelectorAll('[data-initial]')
            if (fields.length) {
                fields.forEach(function (element) {
                    vm[element.name] = vm.$refs[element.name].dataset.initial
                })
            }

            window.lozadObserver.observe();

            /* Update view hight on resize */
            this.getScreenHeight();
            window.addEventListener("resize", this.getScreenHeight);

            /* Mobile menu */
            window.addEventListener('scroll', this.handleScroll);
            this.checkYPosition();

            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
        },
        beforeDestroy() {
            // window.removeEventListener('scroll', this.onWindowScroll);
        },
        methods: {
            onWindowScroll() {
            },

            onSubmit(e) {
                e.target.submit()
            },

            closeStatusBar(slug) {
                VueCookies.set(`status-${slug}`, 'hidden', '7d');
                this.showStatusBar = false;
            },
            getScreenHeight() {
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`)
                /*setTimeout(function () {
                    window.scrollTo(0,1)
                }, 0)*/
            },
            toggleMobileMenu() {
                this.mobileMenuActive = !this.mobileMenuActive;

                const targetElement = document.querySelector('.site-content');
                this.mobileMenuActive ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement);
            },
            checkYPosition() {
                if (window.pageYOffset > 1) {
                    this.navClassObject.active = true;
                } else {
                    this.navClassObject.active = false;
                    this.navClassObject.hide = false;
                }
            },
            handleScroll() {
                let currentScrollPosition = window.pageYOffset;
                this.lastKnownScrollPosition < currentScrollPosition ? this.navClassObject.hide = true : this.navClassObject.hide = false;
                this.checkYPosition();
                if (this.mobileMenuActive) this.navClassObject.hide = false;
                this.lastKnownScrollPosition = currentScrollPosition;
            },
            setProduct(product) {
                document.getElementById('product-field').value = product
            }
        },
        created() {
            // window.addEventListener('scroll', this.onWindowScroll);

            var urlParams = new URLSearchParams(window.location.search);
            if(urlParams.get('scroll') !== null) {
                this.$smoothScroll({
                    scrollTo: document.getElementById(urlParams.get('scroll')),
                    updateHistory: false
                })
            }
        }
    });

    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
